import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const scholaApi = createApi({
  reducerPath: 'scholaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createOTP: builder.mutation({
      query: ({ method, email, phoneNumber }) => ({
        url: 'otp/createOtp',
        method: 'post',
        body: { method, email, phoneNumber },
      }),
    }),
    validateOTP: builder.mutation({
      query: ({ codeId, code }) => ({
        url: 'otp/verifyOtp',
        method: 'post',
        body: { codeId, code },
      }),
    }),
  }),
});

export const { useCreateOTPMutation, useValidateOTPMutation } = scholaApi;
