import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { DivIcon } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import styles from './leaflet-map.module.scss';

export const LeafletMap = ({ center, height = '200px', children }) => {
  return (
    <MapContainer
      style={{ height }}
      className={styles.map}
      center={center}
      zoom={14}
      scrollWheelZoom={false}
      aria-label="School and user locations map">
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      {children}
    </MapContainer>
  );
};

const LeafletMarker = ({ position, Icon, children }) => {
  const customMapMarker = (Icon) => {
    return new DivIcon({
      html: ReactDOMServer.renderToString(<Icon />),
      className: styles.icon,
    });
  };
  const icon = customMapMarker(Icon);

  return (
    <Marker position={position} icon={icon} aria-label="School Location Marker">
      <Popup>{children}</Popup>
    </Marker>
  );
};

LeafletMap.Marker = LeafletMarker;
