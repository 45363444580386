import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  featuresSelected: [],
};

export const schoolResults = createSlice({
  name: 'schoolResults',
  initialState,
  reducers: {
    addFeature: (state, { payload }) => {
      state.featuresSelected = [...state.featuresSelected, payload];
    },
    removeFeature: (state, { payload }) => {
      state.featuresSelected = state.featuresSelected.filter((item) => item !== payload);
    },
    setFeature: (state, { payload }) => {
      if (!state.featuresSelected.includes(payload)) {
        state.featuresSelected = [...state.featuresSelected, payload];
      } else {
        state.featuresSelected = state.featuresSelected.filter((item) => item !== payload);
      }
    },
    setMultipleFeatures: (state, { payload }) => {
      state.featuresSelected = [...state.featuresSelected, ...payload];
    },
  },
});

export default schoolResults.reducer;

export const { addFeature, removeFeature, setFeature, setMultipleFeatures } = schoolResults.actions;
