import React from 'react';
import styles from './loading-profile.module.scss';

export const LoadingProfile = () => {
  return (
    <>
      <div className={styles.loading}>
        <div className={styles.main}>
          <div className={styles.main_top} />
          <div className={styles.main_bottom} />
          <div className={styles.main_bottom} />
          <div className={styles.main_bottom} />
          <div className={styles.main_bottom} />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <div className={styles.picture} />
            <div className={styles.name} />
          </div>
          <div className={styles.name} />
          <div className={styles.block} />
          <div className={styles.block} />
          <div className={styles.block} />
        </div>
      </div>
      <div className={styles.fullWidth} />
    </>
  );
};
