import React, { useMemo, useState, useEffect } from 'react';
import styles from './hero-buttons.module.scss';
import { ViewFilled24 } from '@carbon/icons-react';
import { tString } from 'components/translation/Translation';
import Button from 'components/button/Button';

export const HeroButtons = ({ className, applyAction, contactAction, isEnrolling, children }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div className={styles.buttonFlex}>
        {isEnrolling && (
          <Button
            id="btnApplicationOpen"
            color="green"
            fullWidthMobile
            onClick={applyAction}
            className={styles.primary}>
            {tString('schoolProfile:CTA.applyNow')}
          </Button>
        )}
        <Button
          fullWidthMobile
          color={!isEnrolling ? 'green' : 'secondary'}
          id="btnContactModalOpen"
          onClick={contactAction}>
          {tString('contactSchool:contactSchool')}
        </Button>
        {/* <CurrentViews /> */}
        {children}
      </div>
    </div>
  );
};

const getRandomViewerCount = () => {
  return Math.floor(Math.random() * (27 - 13 + 1)) + 13;
};

export const CurrentViews = ({ schoolId }) => {
  const [viewers, setViewers] = useState(() => {
    const savedViewers = localStorage.getItem(`viewers-${schoolId}`);
    return savedViewers !== null ? JSON.parse(savedViewers) : getRandomViewerCount();
  });

  const [animatedViewers, setAnimatedViewers] = useState(viewers);

  useEffect(() => {
    localStorage.setItem(`viewers-${schoolId}`, JSON.stringify(viewers));
  }, [viewers, schoolId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setViewers(getRandomViewerCount());
    }, 28000);

    return () => clearInterval(intervalId);
  }, [schoolId]);

  useEffect(() => {
    if (animatedViewers === viewers) return;

    const step = animatedViewers < viewers ? 1 : -1;
    const animationInterval = setInterval(() => {
      setAnimatedViewers((prev) => {
        const newValue = prev + step;
        if ((step > 0 && newValue >= viewers) || (step < 0 && newValue <= viewers)) {
          clearInterval(animationInterval);
          return viewers;
        }
        return newValue;
      });
    }, 100); // Adjust the speed of animation here

    return () => clearInterval(animationInterval);
  }, [viewers, animatedViewers]);

  return (
    <div className={styles.badgeRadomGray}>
      <div>
        <ViewFilled24 className={styles.iconView} />
      </div>
      <span className={styles.numRandom}>{animatedViewers}</span> Parents Viewing
    </div>
  );
};
