import React, { useMemo } from 'react';
import styles from './open-enrollment.module.scss';
import i18next from 'i18next';
import { t } from 'components/translation/Translation';
import classNames from 'classnames';

export const OpenEnrollment = ({ deadlineDate, className, hero }) => {
  const containerStyles = {
    [styles.heroContainer]: hero,
    [styles.container]: !hero,
  };

  const enrollmentStyles = {
    [styles.heroEnrollment]: hero,
    [styles.enrollment]: !hero,
  };

  const contentStyles = {
    [styles.heroContent]: hero,
    [styles.content]: !hero,
  };

  const enrollmentDate = useMemo(() => {
    const date = new Date(deadlineDate);
    const today = new Date();

    const validDate = date > today;

    if (!validDate) return null;

    const lang = i18next.language === 'es' ? 'es-ES' : 'en';

    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC', // Force UTC timezone interpretation
    };

    return date.toLocaleDateString(lang, options);
  }, [i18next.language]);

  return (
    <div className={`${classNames(containerStyles)} ${className}`} data-testid="open-enrollment">
      <div className={`${classNames(enrollmentStyles)}`}>
        <div className={styles.enrollment_icon}>
          <img
            className={styles.bookImage}
            src="https://static.schola.com/profile/profile-book.webp"
            alt="notebook"
            width="40"
            height="55"
          />
        </div>
        <div className={`${classNames(contentStyles)}`}>
          <p className={`${styles.description}`}>
            {t('schoolProfile:openEnrollment.open')} {t('schoolProfile:openEnrollment.seats')}
          </p>
          {enrollmentDate && (
            <span className={styles.deadline}>
              {t('schoolProfile:openEnrollment.deadline')} <span data-testid="deadline">{enrollmentDate}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
